<template>
	<Dialog :dialog="claimDialog" :dialog-width="900">
		<template #title
			>Status Remark
			<v-chip
				class="ml-2 mr-2 text-capitalize"
				:color="getStatusColor(status_remark.drop_status)"
				small
				label
				v-if="status_remark && status_remark.drop_status"
				>{{ status_remark.drop_status }}</v-chip
			>
			<v-chip
				class="ml-2 mr-2 text-capitalize"
				outlined
				color="red white--text"
				small
				label
				v-if="meetingSingle && meetingSingle.category"
				>{{ meetingSingle.category.replace("-", " ") }}</v-chip
			>
			<!-- <v-chip class="mb-1 mr-1" small>
				<v-avatar left small>
					<img v-if="meetingSingle.profile_img" :src="meetingSingle.profile_img" />
					<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
				</v-avatar>
				<span v-if="meetingSingle && meetingSingle.user_name">{{ meetingSingle.user_name }}</span>
			</v-chip> -->
		</template>
		<template v-slot:body>
			<v-form
				ref="claimApprovalForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="mb-3" v-if="actionType == 'mark_as_postponed'">
					<v-col lg="3" cols="12" class="">
						<Label for="" class="fw-500 d-flex required">Meeting Date:</Label>
					</v-col>
					<v-col md="9" class="py-0">
						<DatePicker
							hideTopMargin
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							id="meeting-date"
							placeholder="Meeting Date"
							v-model="status_remark.meeting_date"
							:rules="[vrules.required(status_remark.meeting_date, 'Date')]"
							:class="{
								required: !status_remark.meeting_date,
							}"
							:min-date="minDate"
						></DatePicker>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col lg="3" cols="12" class="">
						<Label for="" class="fw-500 d-flex required"> Remark:</Label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput
							:rows="4"
							dense
							:word-limit="200"
							hide-details
							placeholder="Enter remark..."
							v-model="status_remark.remark"
							:rules="[vrules.required(status_remark.remark, 'Remark')]"
							:class="{
								required: !status_remark.remark,
							}"
						></TextAreaInput>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col cols="3" class="">
						<div class="">
							<label class="fw-500">Attachment</label>
						</div>
					</v-col>
					<v-col cols="9" class="">
						<template v-for="(file, index) in files">
							<div class="mb-2" :key="index">
								<v-row>
									<v-col cols="9">
										<span class="w-30 pr-3" style="overflow: hidden">
											<v-file-input
												:id="`document-file-${index}`"
												placeholder="Select File"
												outlined
												class=""
												prepend-icon=""
												prepend-inner-icon="mdi-attachment"
												hide-details
												v-model="file.file"
												v-on:change="updateFile(index, $event)"
												v-on:click:clear="updateFile(index, $event)"
											></v-file-input>
										</span>
										<span class="w-20 pr-3">
											<TextInput
												hideTopMargin
												:id="`document-name-${index}`"
												v-model="file.name"
												hide-details
												placeholder="File Name"
												:suffix="file.suffix"
											></TextInput>
										</span>
									</v-col>
									<v-col cols="3">
										<span class="w-10 pr-3">
											<v-btn
												:disabled="files.length < 2"
												v-on:click="removeFile(index)"
												color="red lighten-1 white--text"
												class=""
												tile
												depressed
												small
												outlined
												min-width="30"
												width="30"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</span>
										<span class="w-10 pr-3">
											<v-btn
												color="blue darken-4 white--text"
												class=""
												tile
												depressed
												small
												min-width="30"
												width="30"
												v-on:click="addMore()"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</span>
									</v-col>
								</v-row>
							</div>
						</template>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-row class="delete-dialog">
				<v-col md="12" class="py-0 my-auto">
					<p class="btx-p m-0 text-align-center" style="text-align: center">
						<span
							class="red--text lighten-1 font-level-1-bold"
							style="font-size: 19px !important; color: red !important"
							>Are you sure, You want to
							<span style="color: #24326d"
								><template v-if="status_remark.drop_status == 'Mark as Completed'">Complete</template
								><template v-if="status_remark.drop_status == 'Mark as Postponed'">Postpone</template>
								<template v-if="status_remark.drop_status == 'Mark as Cancelled'">Cancel</template></span
							>
							this Meeting
						</span>
					</p>
				</v-col>
			</v-row>
			<template>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					:color="getStatusColor(status_remark.drop_status)"
					tile
					v-on:click="onSubmit()"
				>
					YES! Please
				</v-btn>
			</template>
			<!-- <template v-if="status_remark.drop_status == 'Rejected'">
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="onSubmit()"
				>
					Reject
				</v-btn>
			</template> -->
			<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import { POST } from "@/core/services/store/request.module"; //GET
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
import MomentJS from "moment-timezone";
import TextInput from "@/view/components/TextInput";
// import { filter } from "lodash";
//import { concat } from "lodash";
export default {
	name: "ClaimApprovalDialog",
	data() {
		return {
			minDate: MomentJS().format("YYYY-MM-DD"),
			files: [
				{
					file: null,
					name: null,
				},
			],
			claimDialog: false,
			formValid: true,
			pageLoading: false,
			meetingSingle: {},
			status_remark: {
				drop_status: null,
				remark: null,
				drop_value: 0,
				amount: 0,
				meeting_date: null,
			},
			requestedAmount: 0,
			summaryData: {},
		};
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		actionType: {
			type: [String, Number],
			default: null,
		},
		claimId: {
			type: [String, Number],
			default: null,
		},
		claimData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		dialog: {
			handler(param) {
				this.claimDialog = param;
				if (param) {
					this.init();
				}
			},
			immediate: true,
		},
	},
	methods: {
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files?.length < 1) {
				this.addMore();
			}
		},
		closeDialog() {
			this.$emit("close", true);
		},
		onSubmit() {
			const formErrors = this.validateForm(this.$refs["claimApprovalForm"]);
			if (formErrors.length) {
				if (!this.status_remark.remark)
					this.$store.commit(SET_ERROR, [{ model: true, message: `Remark is required!` }]);

				if (this.status_remark.drop_value == 3 && !this.status_remark.meeting_date)
					this.$store.commit(SET_ERROR, [{ model: true, message: `Date is required!` }]);

				return false;
			}

			//concat('','');
			// if (this.status_remark.drop_status == "Approved") {
			// 	if (this.status_remark.amount > this.summaryData[0].balance) {
			// 		const _error = [
			// 			{
			// 				message: "Amount is not available",
			// 				model: true,
			// 				timeout: 2000,
			// 			},
			// 		];
			// 		this.$store.commit(SET_ERROR, _error);
			// 		return false;
			// 	}
			// }

			// if (this.status_remark.drop_status == "Rejected") {
			// 	this.status_remark.amount = this.requestedAmount;
			// }
			//url: `meeting-status/${action}/${this.meetingId}`,

			this.pageLoading = true;
			let formData = new FormData();
			formData.append(
				"meeting_date",
				this.status_remark.meeting_date ? this.status_remark.meeting_date : null
			);
			formData.append("remark", this.status_remark.remark ? this.status_remark.remark : null);
			for (let i = 0; i < this.files?.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}
			this.$store
				.dispatch(POST, {
					url: `meeting-status-with-remark/${this.actionType}/${this.claimId}`,
					data: formData,
				})
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success! Status updated successfully." },
					]);
					this.status_remark = {};

					this.$refs.claimApprovalForm.reset();
					this.$emit("close", false);
					this.$emit("success", data);
				

				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			//this.getCategoryOptions();
			if (this.actionType == "mark_as_completed") {
				this.status_remark.drop_status = "Mark as Completed";
				this.status_remark.drop_value = 1;
			} else if (this.actionType == "mark_as_cancelled") {
				this.status_remark.drop_status = "Mark as Cancelled";
				this.status_remark.drop_value = 2;
			} else if (this.actionType == "mark_as_postponed") {
				this.status_remark.drop_status = "Mark as Postponed";
				this.status_remark.drop_value = 3;
			}
			this.meetingSingle = this.claimData;
			//this.requestedAmount = Number(this.meetingSingle.amount).toFixed(2);
			//this.status_remark.amount = this.requestedAmount;
		},
		// getCategoryOptions() {
		// 	const _this = this;
		// 	_this.$store
		// 		.dispatch(GET, {
		// 			url: "setting/claim/option/" + this.claimId,
		// 		})
		// 		.then((data) => {
		// 			const expenseList = data.claim_categories;
		// 			this.summaryData = filter(expenseList, (row) => {
		// 				return row.category == _this.claimData.expense_category;
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			_this.logError(error);
		// 		});
		// },
		getStatusColor(status) {
			if (status == "Mark as Completed") {
				return "green  white--text";
			} else if (status == "Mark as Cancelled") {
				return "red white--text";
			} else if (status == "Mark as Postponed") {
				return "blue white--text";
			}
		},
	},
	components: {
		Dialog,
		TextAreaInput,
		DatePicker,
		TextInput,
	},
};
</script>
